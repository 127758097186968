<template>
  <section class="section section-hero">
    <div class="section-hero-bg">
      <div class="section section-hero-body section-hero-body-carousel">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div
                id="carouselArda"
                class="carousel slide"
                data-bs-ride="carousel"
              >
                <ol class="carousel-indicators">
                  <li
                    data-bs-target="#carouselArda"
                    data-bs-slide-to="0"
                    class="active"
                  />
                  <li
                    data-bs-target="#carouselArda"
                    data-bs-slide-to="1"
                    class=""
                  />
                  <li
                    data-bs-target="#carouselArda"
                    data-bs-slide-to="2"
                    class=""
                  />
                  <li
                    data-bs-target="#carouselArda"
                    data-bs-slide-to="3"
                    class=""
                  />
                  <li
                    data-bs-target="#carouselArda"
                    data-bs-slide-to="4"
                    class=""
                  />
                </ol>
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <div class="row">
                      <div class="col-12 col-lg-6 col-xl-6 order-2 order-lg-1">
                        <SectionTitle
                          icon="icon-services"
                          h2="Vállalkozását, eladásait mennyire érintette és érinti a pandémia?"
                          h2small="2021-ben az online kommunikáció elengedhetetlen"
                          createdate=""
                        />
                        <p class="d-block d-lg-none d-xl-block lead">
                          A korlátozó intézkedések miatt az emberek interneten
                          töltött ideje jelentősen megnőtt, vásárlási szokásaik
                          megváltoztak. <br />
                          Itt a pillanat létrehozni a céges bemutatkozásokat, a
                          szolgáltatásokra, termékekre kihegyezett landing
                          oldalakat vagy aktualizálni a régi tartalmakat.
                        </p>

                        <div class="d-grid col-xl-10 m-auto">
                          <router-link
                            class="btn btn-rainbow pt-4 pb-4"
                            to="/szolgaltatasaink/"
                            @click="hidden = !hidden"
                          >
                            <span>Ismerje meg szolgáltatásainkat!</span>
                          </router-link>
                        </div>
                      </div>

                      <div
                        class="col-12 col-lg-6 col-xl-6 pt-lg-5 order-1 order-lg-2"
                      >
                        <div class="container">
                          <div class="row">
                            <div class="col-6 col-lg-12 m-auto">
                              <img
                                src="@/assets/img/all/img-box-covid19-left.png"
                                alt="Szolgáltatásaink"
                                class="img-fluid text-center"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="carousel-item">
                    <div class="row">
                      <div class="col-12 col-lg-6 col-xl-6 order-2 order-lg-2">
                        <SectionTitle
                          icon="icon-services"
                          h2="Évről-évre halogatta a döntést és így cégének még mindig nincs weboldala?"
                          h2small="2021-ben az internetes jelenlét szükségszerű"
                          createdate=""
                        />
                        <p class="d-block d-lg-none d-xl-block lead">
                          Kezdje a 2021-es évet új lendülettel, ismertesse meg
                          az online világgal termékeit vagy szolgáltatásait!
                          <br class="d-block d-lg-none" />
                          Akár <em>pár nap alatt elindulhat</em> modern
                          kinézetű, gyorsan betöltődő, mobilon is jól
                          használható termékeit, szolgáltatásait, önmagát
                          bemutató weblapja és megkezdheti az ügyfélszerzést.
                        </p>

                        <div class="d-grid col-xl-8 m-auto">
                          <router-link
                            class="btn btn-rainbow pt-4 pb-4"
                            to="/szolgaltatasaink/landing-oldal-keszites"
                            @click="hidden = !hidden"
                          >
                            <span>Kezdjük el a közös munkát!</span>
                          </router-link>
                        </div>
                      </div>
                      <div
                        class="col-12 col-lg-6 col-xl-6 pt-lg-5 order-1 order-lg-1"
                      >
                        <div class="container">
                          <div class="row">
                            <div class="col-6 col-lg-12 m-auto">
                              <img
                                src="@/assets/img/all/img-box-services-landing-page-left.png"
                                alt="Landing oldal készítés"
                                class="img-fluid text-center"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="carousel-item">
                    <div class="row">
                      <div class="col-12 col-lg-6 col-xl-6 order-2 order-lg-1">
                        <SectionTitle
                          icon="icon-services"
                          h2="Figyelemfelhívó bannerekkel hirdetné termékeit vagy szolgáltatásait?"
                          h2small="Bannerkészítés cégeknek, ügynökségeknek"
                          createdate=""
                        />
                        <p class="d-block d-lg-none d-xl-block lead">
                          Igazodva vállalkozásának arculatához, vagy a már
                          elkészült grafikai tervekhez, rövid határidővel
                          elkészítjük a kívánt méretekben statikus- vagy
                          dinamikus bannereit. <br class="d-block d-lg-none" />
                          Legyen szó Google hirdetésről<em
                            class="d-none d-md-inline-block"
                            >, más rendszerről</em
                          >
                          vagy saját weboldaláról a bannerek vásárlásra
                          ösztönzik majd látogatóit.
                        </p>
                        <div class="d-grid col-xl-8 m-auto">
                          <router-link
                            class="btn btn-rainbow pt-4 pb-4"
                            to="/szolgaltatasaink/landing-oldal-keszites"
                            @click="hidden = !hidden"
                          >
                            <span>Kezdjük el a közös munkát!</span>
                          </router-link>
                        </div>
                      </div>
                      <div
                        class="col-12 col-lg-6 col-xl-6 pt-lg-5 order-1 order-lg-2"
                      >
                        <div class="container">
                          <div class="row">
                            <div class="col-6 col-lg-12 m-auto">
                              <img
                                src="@/assets/img/all/img-box-services-design-left.png"
                                alt="Bannerkészítés cégeknek, ügynökségeknek"
                                class="img-fluid text-center"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="carousel-item">
                    <div class="row">
                      <div class="col-12 col-lg-6 col-xl-6 order-2 order-lg-2">
                        <SectionTitle
                          icon="icon-services"
                          h2="Kínosnak érzi, hogy oldala elavult és az információk nem naprakészek?"
                          h2small="2021-ben az internetes jelenlét szükségszerű"
                          createdate=""
                        />
                        <p class="d-block d-lg-none d-xl-block lead">
                          Mobilon, táblagépen kezelhetetlen a régi módszerekkel
                          készült, unalmas vagy kevés tartalmakkal rendelkező
                          weboldala?
                          <br class="d-block d-lg-none" />
                          Rövid határidővel modernizáljuk weboldalát, melyet
                          utána rendszeresen karbantartunk, hogy érdeklődőket,
                          megrendelőket, vásárlókat szereshessen az internetről!
                        </p>

                        <div class="d-grid col-xl-8 m-auto">
                          <router-link
                            class="btn btn-rainbow pt-4 pb-4"
                            to="/szolgaltatasaink/support-szolgaltatas"
                            @click="hidden = !hidden"
                          >
                            <span>Kezdjük el a közös munkát!</span>
                          </router-link>
                        </div>
                      </div>
                      <div
                        class="col-12 col-lg-6 col-xl-6 pt-lg-5 order-1 order-lg-1"
                      >
                        <div class="container">
                          <div class="row">
                            <div class="col-6 col-lg-12 m-auto">
                              <img
                                src="@/assets/img/all/img-box-services-support-left.png"
                                alt="Support szolgáltatás"
                                class="img-fluid text-center"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!--<div class="carousel-item">
                    <div class="row">
                      <div class="col-12 col-lg-6 col-xl-6 order-2 order-lg-1">
                        <SectionTitle
                          icon="icon-services"
                          h2="Gyors, precíz, határidőre elkészülő frontend (sitebuild) készítése"
                          h2small="Digitális ügynökségeknek, fejlesztő cégeknek"
                          createdate=""
                        />
                        <p class="d-block d-lg-none d-xl-block lead">
                          Kész grafikai anyagokból készülő szabványos, jól
                          átlátható sitebuild HTML5, CSS3 (SCSS), BOOTSTRAP
                          alapokon. <br />
                          A VUE.JS frontend keretrendszer használatával gyors,
                          modern, mobilbarát és könnyen bővíthető új weboldal
                          vagy a meglévő átalakítása nem lehet probléma.
                        </p>

                        <div class="d-grid col-xl-8 m-auto">
                          <router-link
                            class="btn btn-rainbow pt-4 pb-4"
                            to="/szolgaltatasaink/landing-oldal-keszites"
                            @click="hidden = !hidden"
                          >
                            <span>Kezdjük el a közös munkát!</span>
                          </router-link>
                        </div>
                      </div>
                      <div
                        class="col-12 col-lg-6 col-xl-6 pt-lg-5 order-1 order-lg-2"
                      >
                        <div class="container">
                          <div class="row">
                            <div class="col-6 col-lg-12 m-auto">
                              <img
                                src="@/assets/img/all/img-box-services-frontend-development-left.png"
                                alt="Sitebuild"
                                class="img-fluid text-center"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> -->

                  <div class="carousel-item">
                    <div class="row">
                      <div class="col-12 col-lg-6 col-xl-6 order-2 order-lg-2">
                        <SectionTitle
                          icon="icon-products"
                          h2="Ne veszítsen vásárlókat, magyartalan Magento webáruháza miatt!"
                          h2small="Magyar nyelvi csomag Magento 2.x webáruházhoz!"
                          createdate=""
                        />
                        <p class="d-block d-lg-none d-xl-block lead">
                          Folyamatosan frissülő, szakértők által fordított,
                          naprakész és rendszeresen karbantartott magyar
                          fordítási csomag a legújabb verziókhoz! <br />
                          Mennyiségi kedvezményt biztosítunk szabadúszóknak és
                          fejlesztő cégeknek, hogy a fordítás azonnal
                          rendelkezésre álljon.
                        </p>

                        <div class="d-grid col-xl-8 m-auto">
                          <router-link
                            class="btn btn-rainbow pt-4 pb-4"
                            to="/termekeink"
                            @click="hidden = !hidden"
                          >
                            <span>Ismerje meg termékeinket!</span>
                          </router-link>
                        </div>
                      </div>
                      <div
                        class="col-12 col-lg-6 col-xl-6 pt-lg-5 order-1 order-lg-1"
                      >
                        <div class="container">
                          <div class="row">
                            <div class="col-6 col-lg-12 m-auto">
                              <img
                                src="@/assets/img/all/img-box-products-magento-1x-right.png"
                                alt="Magento magyar nyelvi csomag"
                                class="img-fluid text-center"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--<div class="section section-mouse">
        <div class="container">
          <div class="row">
            <div class="col-12 text-center">
              <img
                src="@/assets/img/all/icon-mouse.svg"
                alt=""
                class="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>-->
    </div>
  </section>

  <SectionTitle
    v-once
    icon="icon-about"
    h2="Igérgetések? Határidő csúszások? Hibáktól hemzsegő weboldal? <br class='d-none d-lg-block'> Mindezek horribilis fejlesztési összegekért?"
    h2small=""
    createdate=""
  />

  <section v-once>
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="d-grid col-lg-5 m-auto">
            <router-link
              class="btn btn-van pt-4 pb-4"
              to="#megoldas"
              @click="hidden = !hidden"
            >
              <span class="ps-5 pe-5">Van megoldás!</span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section v-once class="section-body">
    <div class="container">
      <div class="row">
        <div
          class="col-12 col-lg-5 text-center hidden-left col-show col-show-left"
        >
          <img
            src="@/assets/img/all/icon-about-kik.svg"
            alt="Kik az ügyfeleink?"
            class="img-fluid"
          />
          <br />
          <h3 class="mt-2 mt-lg-4">// Kik az ügyfeleink?</h3>
          <p class="lead">
            Olyan vállalkozásokkal, cégekkel, dolgozunk együtt akik termékeiket
            vagy szolgáltatásaikat kiemelt figyelemmel kezelik és fontosnak
            tartják az egymást segítő partneri együttműködést. Ügyfeleink között
            található magyar és külföldi egyéni vállalkozó, micro-, kis- és
            középvállalat is. Vannak olyan partnereink, akikkel már már több,
            mint 20 éve dolgozunk együtt.
          </p>
          <div class="mt-0 mt-lg-5">
            <img
              src="@/assets/img/all/icon-about-hogyan.svg"
              alt="Hogyan készítjük?"
              class="img-fluid"
            />
            <br />
            <h3 class="mt-2 mt-lg-4">// Hogyan készítjük?</h3>
            <p class="lead">
              Az ügyfelek elképzeléseket meghallgatjuk, ésszerű javaslatokat,
              ötleteket teszünk. Próbálunk mindig több megoldást kínálni az
              adott feladatra. Hiszünk a nyílt, őszinte kommunikációban és a
              kölcsönös tiszteletben. Partneri viszonyban a közös siker
              elérésére törekszünk.
            </p>
          </div>
        </div>
        <div class="d-none d-lg-block col-lg-2 mt-0 pt-0 mt-lg-5 pt-lg-5">
          <img
            src="@/assets/img/all/bg-about-line.svg"
            alt="Mit csinálunk?"
            class="img-fluid"
          />
        </div>

        <div
          class="col-12 col-lg-5 mt-5 text-center hidden-right col-show col-show-right"
        >
          <img
            src="@/assets/img/all/icon-about-mit.svg"
            alt="Mit csinálunk?"
            class="img-fluid"
          />
          <br />
          <h3 class="mt-2 mt-lg-4">// Mit csinálunk?</h3>
          <p class="lead">
            20 éves webes tapasztalattal landing oldalakat, sitebuild munkákat,
            weboldalakat készítünk és tartunk karban. Grafikai tervezést és
            kivitelezést is végzünk, legyen az egy egyszerű banner elkészítése
            vagy egy komplex design guide megalkotása.
          </p>

          <div class="mt-lg-5 pt-lg-5">
            <img
              src="@/assets/img/all/icon-about-mennyiert.svg"
              alt="Mennyiért dolgozunk?"
              class="img-fluid"
            />
            <br />
            <h3 class="mt-2 mt-lg-4">// Mennyiért dolgozunk?</h3>
            <p class="lead">
              Magyarországi és nemzetközi viszonylatban is korrekt ár-érték
              aránnyal dolgozunk. Partnereinkkel hosszú távú, sikeres
              együttműködésre törekszünk. Nem célunk az egyszeri alkalomra
              szóló, összecsapott munka.
            </p>
          </div>
        </div>
      </div>
    </div>
    <p id="megoldas" />
  </section>

  <section v-once class="mb-5">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="d-grid col-lg-8 col-xl-6 m-auto">
            <router-link
              class="btn pt-4 pb-4"
              to="/rolunk"
              @click="hidden = !hidden"
            >
              <span class="ps-lg-5 pe-lg-5">
                Ismerje meg cégünk filozófiáját!
              </span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </section>

  <SectionTitle
    icon="icon-services"
    h2="Webes szolgáltatások széles skálája <br class='d-none d-lg-block'> tervezhető határidőkkel és költségekkel"
    h2small="2021-ben a modern, látványos internetes jelenlét elengedhetetlen"
    createdate=""
  />

  <SectionBox
    id="landing-oldal-keszites"
    align="left"
    icon="icon-services"
    imgurl="img-box-services-landing-page-left"
    h2text="Évről-évre halogatta a döntést és így cégének még mindig nincs weboldala? <br class='d-none d-lg-block'> Új szolgáltatását vagy termékét kívánja megismertetni az interneten?"
    h2small=""
    ptext1=""
    createdate=""
    btntext="Ismerje meg a részleteket"
    btnurl="/szolgaltatasaink/landing-oldal-keszites"
    tag1="tanfolyam"
    tag1url=""
    tag2="frontend"
    tag2url="szolgaltatasaink/frontend"
    tag3="Vue.js 3"
    tag3url="szolgaltatasaink/frontend/vuejs"
  />

  <SectionBox
    id="statikus-es-dinamikus-banner-keszites"
    align="right"
    icon="icon-services"
    imgurl="img-box-services-banner-right"
    h2text="Látványos bannerekkel hirdetné cégét, termékét vagy szolgáltatását?"
    h2small=""
    ptext1=""
    createdate=""
    btntext="Ismerje meg a részleteket"
    btnurl="/szolgaltatasaink/statikus-es-dinamikus-banner-keszites"
    tag1="tanfolyam"
    tag1url=""
    tag2="frontend"
    tag2url="szolgaltatasaink/frontend"
    tag3="Vue.js 3"
    tag3url="szolgaltatasaink/frontend/vuejs"
  />

  <SectionBox
    id="support-szolgaltatas"
    align="left"
    icon="icon-services"
    imgurl="img-box-services-support-left"
    h2text="Weboldalán, közösségi oldalain a legfrissebb bejegyzések is több hónaposak? <br class='d-none d-lg-block'> Nincs ideje, energiája foglalkozni vele?"
    h2small=""
    ptext1=""
    createdate=""
    btntext="Ismerje meg a részleteket"
    btnurl="/szolgaltatasaink/support-szolgaltatas"
    tag1="tanfolyam"
    tag1url=""
    tag2="frontend"
    tag2url="szolgaltatasaink/frontend"
    tag3="Vue.js 3"
    tag3url="szolgaltatasaink/frontend/vuejs"
  />

  <SectionBox
    align="right"
    icon="icon-products"
    imgurl="img-box-products-magento-1x-right"
    h2text="Magyarul kommunikáló <br class='d-none d-lg-block'> Magento Open Source 2.x webáruház? A csomag segítségével Magento webáruháza vonzóbb lesz a magyarországi környezetben."
    h2small=""
    ptext1=""
    createdate=""
    btntext="Ismerje meg termékünket!"
    btnurl="/termekeink/magento-2-magyar-nyelvi-modul"
    tag1=""
    tag1url=""
    tag2=""
    tag2url=""
    tag3=""
    tag3url=""
  />

  <div class="section section-title">
    <div class="container">
      <div class="row">
        <div class="col-12 text-center">
          <img
            src="@/assets/img/all/icon-contact.svg"
            alt="Kapcsolat"
            class="img-fluid h2-icon"
          />
          <br />
          <h2>Dolgozzunk együtt!</h2>
          <br />
          <router-link class="" to="/kapcsolat" @click="hidden = !hidden">
            <div class="col-10 col-lg-12 m-auto">
              <img
                src="@/assets/img/all/img-box-about-hello-left.png"
                alt=""
                class="img-fluid"
              />
            </div>
          </router-link>
          <br />
          <h2>
            Ha nem talált a weboldalunkon megfelelő választ a felmerült
            kérdéseire, akkor <br class="d-none d-lg-block" />
            keressen minket elérhetőségeinken!
          </h2>
        </div>
      </div>
    </div>
  </div>

  <section v-once>
    <div class="container">
      <div class="row">
        <div class="col-12 mb-5">
          <div class="d-grid col-lg-7 m-auto">
            <router-link
              class="btn pt-4 pb-4 mt-3 mb-5"
              to="/kapcsolat"
              @click="hidden = !hidden"
            >
              <span class="ps-5 pe-5"
                >Kezdjük el a közös munkát<em class="d-none d-sm-inline-block"
                  >, most</em
                >!</span
              >
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SectionTitle from "@/components/SectionTitle.vue";
import SectionBox from "@/components/SectionBox.vue";
export default {
  components: { SectionTitle, SectionBox },
  data() {
    return {
      show: false,
      activeClass: "is-visible",
      active: null,
      userRange: 1,
    };
  },

  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    isInViewport(el) {
      const rect = el.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth)
      );
    },
    handleScroll() {
      if (window.scrollY > 500) {
        document.querySelector(".col-show-left").classList.add("showElement");
        document.querySelector(".col-show-right").classList.add("showElement");
      } else {
        document
          .querySelector(".col-show-left")
          .classList.remove("showElement");
        document
          .querySelector(".col-show-right")
          .classList.remove("showElement");
      }
    },

    showDetails(i) {
      this.active = i;
    },
  },
};
</script>
